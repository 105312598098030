import axios from "axios";
import { REACT_APP_BASE_URL } from "consts/env";

export const getUserCredentials = async (setSnackbarInfo = () => {}) => {
    const serviceURL = REACT_APP_BASE_URL.trim();

    let userCredentials = {};

    if (localStorage.getItem("miaToken") && localStorage.getItem("sessionId")) {
        setSnackbarInfo({
            message: "Credenciales encontradas - Login en Development exitoso",
            open: true,
            severity: "success",
        });
    } else {
        setSnackbarInfo({
            message:
                "Credenciales no encontradas - Procediendo a setear development token en localstorage",
            open: true,
            severity: "warning",
        });

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {
                service: "TemporalSession",
                params: {},
            },
        };

        try {
            const credentials = await axios.post(
                serviceURL,
                requestOptions.body,
                requestOptions.headers
            );

            if (credentials?.status?.errcode) {
                setSnackbarInfo({
                    message: userCredentials.status.errmsg,
                    severity: "error",
                    open: true,
                });

                return;
            }

            localStorage.setItem(
                "miaToken",
                credentials.data.result[0].hooliToken
            );
            localStorage.setItem(
                "sessionId",
                credentials.data.result[0].sessionId
            );

            setSnackbarInfo({
                message: "Credenciales seteadas - Login en Development exitoso",
                open: true,
                severity: "success",
            });
        } catch (err) {
            console.error(`Error: ${err}`);
        }
    }

    return (userCredentials = {
        miaToken: localStorage.getItem("miaToken"),
        sessionId: localStorage.getItem("sessionId"),
    });
};
